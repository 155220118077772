<template>
  <div>
    <v-card elevation="2" outlined shaped tile>
      <v-card-text>
        <v-row class="analysis_filter">
          <v-col cols="12" sm="4" md="2">
            <v-menu
              ref="start_date_menu"
              v-model="start_date_menu"
              :close-on-content-click="false"
              :return-value.sync="filterCriteria['start_date']"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterCriteria['start_date']"
                  v-bind:label="$t('brands.start_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @change="onChangeData"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterCriteria['start_date']"
                type="month"
                no-title
                scrollable
                :locale="this.$i18n.locale"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="start_date_menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="onChangeStartDate">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-menu
              ref="end_date_menu"
              v-model="end_date_menu"
              :close-on-content-click="false"
              :return-value.sync="filterCriteria['end_date']"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterCriteria['end_date']"
                  v-bind:label="$t('brands.end_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterCriteria['end_date']"
                type="month"
                no-title
                scrollable
                :locale="this.$i18n.locale"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="end_date_menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="onChangeEndDate">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-autocomplete
              v-model="filterCriteria['users']"
              v-bind:label="$t('User')"
              item-text="username"
              item-value="id"
              :items="userlist"
              hide-no-data
              filled
              dense
              multiple
              clearable
              @change="onChangeData"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-autocomplete
              v-model="filterCriteria['parents']"
              v-bind:label="$t('expensesParentUserName')"
              :items="parents"
              item-text="username"
              item-value="id"
              hide-no-data
              filled
              dense
              multiple
              clearable
              @change="onChangeData"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-autocomplete
              v-model="filterCriteria['departments']"
              v-bind:label="$t('salesforce.department')"
              :items="departments"
              hide-no-data
              filled
              dense
              multiple
              clearable
              @change="onChangeData"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-autocomplete
              v-model="filterCriteria['projects']"
              v-bind:label="$t('project')"
              v-bind:placeholder="$t('start_typing_to_search')"
              item-text="name"
              item-value="id"
              :search-input.sync="search_projects"
              :loading="isProjectsSearching"
              :items="filtered_projects"
              hide-no-data
              filled
              dense
              multiple
              clearable
              @change="onChangeData"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <LineBarChart
              chartTitle="Evol SUM KM * GPV * Day"
              :data="analysis_chart_data_km"
            />
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <LineBarChart
              chartTitle="Evol SUM Expenses * GPV * Day"
              :data="analysis_chart_data_oe"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <LineBarChart
              chartTitle="Evol AVG KM * GPV * Day"
              :data="analysis_chart_data_km_avg"
            />
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <LineBarChart
              chartTitle="Evol AVG Expenses * GPV * Day"
              :data="analysis_chart_data_oe_avg"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <kmDataTable ref="kmtable" />
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <expensesDataTable ref="oetable" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import LineBarChart from "@/components/charts/LineBarChart";
import StatisticsDatas from "./statistics_data";
import kmDataTable from "./kmDataTable";
import expensesDataTable from "./expensesDataTable";

export default {
  name: "Analysis",
  components: {
    StatisticsDatas,
    LineBarChart,
    kmDataTable,
    expensesDataTable,
  },
  data() {
    return {
      departments: [],
      projects: [],
      parents: [],
      userlist: [],
      filterCriteria: {
        users: [],
        parents: [],
        departments: [],
        projects: [],
        start_date: new Date().toISOString().substr(0, 7),
        end_date: new Date().toISOString().substr(0, 7),
      },
      start_date_menu: false,
      end_date_menu: false,
      userId: [],
      search_projects: null,
      isProjectsSearching: false,
      filtered_projects: [],
    };
  },
  watch: {
    async search_projects(val) {
      this.search_brands(val);
    },
  },
  computed: {
    ...mapGetters("expenses", [
      "analysis_chart_data_km",
      "analysis_chart_data_oe",
      "analysis_chart_data_km_avg",
      "analysis_chart_data_oe_avg",
      "analysis_top_titles_km",
      "analysis_top_titles_oe",
    ]),
  },
  methods: {
    async search_brands(val) {
      this.isProjectsSearching = true;
      let fetch_url = "expenses/analysis/get_projects?filter_name=" + val;
      this.prevsearch = val;
      const resp = await ApiService.get(fetch_url);
      this.filtered_projects = resp.data;
      this.isProjectsSearching = false;
    },
    async init() {
      let current_date = new Date();
      let last_year = new Date(current_date.getFullYear() - 1, 0, 10);
      this.filterCriteria["start_date"] = last_year.toISOString().substr(0, 7);
      try {
        let get_search_params_url = "expenses/analysis/get_search_params";
        const response = await ApiService.get(get_search_params_url);
        this.departments = response.departments;
        this.filtered_projects = response.projects;
        this.parents = response.parents;
        this.userlist = response.userlist;
        this.onChangeData();
      } catch (error) {
        console.log(error);
      }
    },
    isStartDateGreaterThanEndDate() {
      let start_date = this.filterCriteria["start_date"].split("-");
      let end_date = this.filterCriteria["end_date"].split("-");
      if (parseInt(start_date[0]) > parseInt(end_date[0])) {
        this.filterCriteria["end_date"] = this.filterCriteria["start_date"];
      } else if (
        parseInt(start_date[0]) === parseInt(end_date[0]) &&
        parseInt(start_date[1]) > parseInt(end_date[1])
      ) {
        this.filterCriteria["end_date"] = this.filterCriteria["start_date"];
      }
    },
    onChangeStartDate(date) {
      this.$refs.start_date_menu.save(this.filterCriteria["start_date"]);
      this.isStartDateGreaterThanEndDate();
      this.onChangeData();
    },
    onChangeEndDate(date) {
      this.$refs.end_date_menu.save(this.filterCriteria["end_date"]);
      this.isStartDateGreaterThanEndDate();
      this.onChangeData();
    },
    async onChangeData() {
      this.$store.dispatch(
        "expenses/setAnalysisChartDataKM",
        this.filterCriteria
      );
      this.$store.dispatch(
        "expenses/setAnalysisChartDataKMAVG",
        this.filterCriteria
      );
      this.$store.dispatch(
        "expenses/setAnalysisChartDataOE",
        this.filterCriteria
      );
      this.$store.dispatch(
        "expenses/setAnalysisChartDataOEAVG",
        this.filterCriteria
      );
      this.$refs.kmtable.loadTable(this.filterCriteria);
      this.$refs.oetable.loadTable(this.filterCriteria);
    },
  },
  async mounted() {
    this.init();
  },
};
</script>

<style></style>
