<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" class="text-right">
        <v-btn color="warning" dark class="ml-2" @click="downloadExcel">
          <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
          Export
        </v-btn>
        <v-btn
          color="warning"
          dark
          class="ml-2"
          @click="downloadExcelDailyOtherExpenses"
        >
          <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
          Export Day Detail
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" sm="12" md="12">
        <ve-table
          id="analysis-table-expenses"
          fixed-header
          max-height="calc(100vh - 330px)"
          :columns="columns"
          :table-data="tableData"
          :border-around="true"
          :border-x="true"
          :border-y="true"
          :scroll-width="1500"
          :sort-option="sortOption"
          :cell-style-option="cellStyleOption"
        />
        <div v-show="showEmpty" class="empty-data">Data Empty.</div>
        <div class="table-pagination mt-2 text-right">
          <ve-pagination
            :total="totaltableData"
            :page-index="pageIndex"
            :page-size="pageSize"
            :page-size-option="pageOptions"
            @on-page-number-change="pageNumberChange"
            @on-page-size-change="pageSizeChange"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import moment from "moment";
import _ from "lodash";
import download from "js-file-download";
export default {
  name: "expensesDataTable",
  data() {
    return {
      tableData: [],
      totaltableData: 0,
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        year: {},
        month: {},
        parentId: {},
        expense_total: {},
        food_total: {},
        transport_total: {},
        lodgment_total: {},
        otherexpenses_total: {},
      },
      filterable_col_list: [
        "userId",
        "username",
        "parent_username",
        "year",
        "month",
        "expense_total",
        "food_total",
        "transport_total",
        "lodgment_total",
        "otherexpenses_total",
        "parentId",
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
      },
      showEmpty: false,
      filterable_parent_list: [],
    };
  },
  computed: {
    columns() {
      return [
        {
          key: "userId",
          title: this.$t("Id User"),
          field: "userId",
          align: "left",
          sortBy: "",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span class="text-bold">{row.user && row.user.id}</span>;
          },
        },
        {
          key: "username",
          title: this.$t("salesforce.UserName"),
          field: "username",
          align: "left",
          sortBy: "",
          width: 80,
          fixed: "left",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span class="text-bold">
                {row.user && row.user.name + " " + row.user.surname}
              </span>
            );
          },
        },
        {
          key: "usertype",
          title: this.$t("salesforce.user_type"),
          field: "usertype",
          align: "left",
          sortBy: "",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span class="text-bold">{row.user && row.user.role}</span>;
          },
        },
        {
          key: "parentId",
          title: this.$t("expensesParentUserName"),
          field: "parentId",
          align: "left",
          sortBy: "",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span class="text-bold">
                {row.user &&
                  row.user.Parent &&
                  row.user.Parent.name + " " + row.user.Parent.surname}
              </span>
            );
          },
        },
        {
          key: "year",
          title: this.$t("Year"),
          field: "year",
          align: "left",
          sortBy: "",
          width: 80,
        },
        {
          key: "month",
          title: this.$t("Month"),
          field: "month",
          align: "left",
          sortBy: "",
          width: 80,
        },
        {
          key: "expense_total",
          title: this.$t("Total Expenses"),
          field: "expense_total",
          align: "left",
          sortBy: "",
          width: 120,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span class="text-bold">
                {row.expense_total && row.expense_total.toFixed(2)}
              </span>
            );
          },
        },
        {
          key: "food_total",
          title: this.$t("Food"),
          field: "food_total",
          align: "left",
          sortBy: "",
          width: 80,
        },
        {
          key: "transport_total",
          title: this.$t("Transport"),
          field: "transport_total",
          align: "left",
          sortBy: "",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span class="text-bold">
                {row.transport_total && row.transport_total.toFixed(2)}
              </span>
            );
          },
        },
        {
          key: "lodgment_total",
          title: this.$t("Hotels"),
          field: "lodgment_total",
          align: "left",
          sortBy: "",
          width: 80,
        },
        {
          key: "otherexpenses_total",
          title: this.$t("Others"),
          field: "otherexpenses_total",
          align: "left",
          sortBy: "",
          width: 80,
        },
      ];
    },
  },
  methods: {
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#analysis-table-expenses"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        cancelFilterKey === "username" ||
        cancelFilterKey === "parent_username" ||
        cancelFilterKey === "approvername" ||
        cancelFilterKey === "gpv_comment" ||
        cancelFilterKey === "spv_comment"
      ) {
        this.filterCriteria[cancelFilterKey] = null;
      } else {
        this.filterCriteria[cancelFilterKey] = {};
      }
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null && Object.keys(value).length > 0) {
          filterModel.push({
            columnField: key,
            filterValue: value,
          });
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#analysis-table-expenses .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map((filter_item) => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    loadTable(criteria) {
      this.filterCriteria = criteria;
      this.getDataFromApi();
    },
    async getDataFromApi() {
      // this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "expenses/analysis/otherexpenses";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        // this.close();
      } catch (error) {
        // this.close();
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "expenses/analysis/otherexpensesdownloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob",
      });
      download(response, "analysisOtherExpenses.xlsx");
      this.close();
    },
    async downloadExcelDailyOtherExpenses() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "expenses/analysis/oedownloadexceldailydetail";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob",
      });
      download(response, "analysisOEDailyDetail.xlsx");
      this.close();
    },
    init() {
      // this.getDataFromApi();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
