<template>
<div class="analysis_statistics text-center">
    <v-row v-if="contentCategory === 'KM'">
        <v-col cols="12" sm="12" md="12">
            <v-row>
                <v-col cols="12" sm="6" md="3"></v-col>
                <v-col cols="12" sm="6" md="3"><span class="title">{{ this.$t("Current Month") }}</span></v-col>
                <v-col cols="12" sm="6" md="3"><span class="title">{{ this.$t("Prev Month") }}</span></v-col>
                <v-col cols="12" sm="6" md="3"><span class="title">{{ this.$t("AVG Last 6M") }}</span></v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="3"><span class="title">{{ this.$t("AVG KM") }}</span></v-col>
                <v-col cols="12" sm="6" md="3"><span>{{fetchData.avg_km.cur_month.toFixed(2)}}</span></v-col>
                <v-col cols="12" sm="6" md="3"><span>{{fetchData.avg_km.prev_month.toFixed(2)}}</span></v-col>
                <v-col cols="12" sm="6" md="3"><span>{{fetchData.avg_km.avg_rest.toFixed(2)}}</span></v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="3"><span class="title">TOTAL KM</span></v-col>
                <v-col cols="12" sm="6" md="3"><span>{{fetchData.total_km.cur_month.toFixed(2)}}</span></v-col>
                <v-col cols="12" sm="6" md="3"><span>{{fetchData.total_km.prev_month.toFixed(2)}}</span></v-col>
                <v-col cols="12" sm="6" md="3"><span>{{fetchData.total_km.avg_rest.toFixed(2)}}</span></v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row v-else>
        <v-col cols="12" sm="12" md="12">
            <v-row>
                <v-col cols="12" sm="6" md="3"></v-col>
                <v-col cols="12" sm="6" md="3"><span class="title">{{ this.$t("Current Month") }}</span></v-col>
                <v-col cols="12" sm="6" md="3"><span class="title">{{ this.$t("Prev Month") }}</span></v-col>
                <v-col cols="12" sm="6" md="3"><span class="title">{{ this.$t("AVG Last 6M") }}</span></v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="3"><span class="title">{{ this.$t("Total") }}</span></v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.total.cur_month.toFixed(2)}}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.total.prev_month.toFixed(2)}}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.total.avg_rest.toFixed(2)}}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="3"><span class="title">{{ this.$t("Food") }}</span></v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.food.cur_month.toFixed(2)}}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.food.prev_month.toFixed(2)}}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.food.avg_rest.toFixed(2)}}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="3"><span class="title">{{ this.$t("Transport") }}</span></v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.transport.cur_month.toFixed(2)}}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.transport.prev_month.toFixed(2)}}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.transport.avg_rest.toFixed(2)}}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="3"><span class="title">{{ this.$t("Hotels") }}</span></v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.hotels.cur_month.toFixed(2)}}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.hotels.prev_month.toFixed(2)}}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.hotels.avg_rest.toFixed(2)}}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="3"><span class="title">{{ this.$t("Others") }}</span></v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.others.cur_month.toFixed(2)}}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.others.prev_month.toFixed(2)}}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <span>{{fetchData.others.avg_rest.toFixed(2)}}</span>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</div>
</template>

<script>
export default {
    name: "StatisticsDatas",
    props: ["contentCategory", "fetchData"],
    data() {
        return {};
    },
};
</script>

<style>
.analysis_statistics .row+.row {
    margin-top: 0px;
}

.analysis_statistics span {
    line-height: 2rem;
    color: #222;
}

.analysis_statistics span.title {
    font-size: 1.1em !important;
    color: #000;
}
</style>
